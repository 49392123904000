import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularKiteCoreModule } from '@kite/angular-kite';
import { AppComponent } from './app.component';
import { RootModule } from './store/root/root.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ComponentLibraryModule } from '@srd/app-common-components';
import { CommonModule } from '@angular/common';
import { httpInterceptorProviders } from './shared/interceptors/http.interceptors.provider';
import { IdentityManagementModule } from './modules/identity-management/identity-management.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    ComponentLibraryModule,
    AngularKiteCoreModule,
    RootModule,
  ],
  bootstrap: [AppComponent],
  providers: [httpInterceptorProviders],
})
export class AppModule {}
