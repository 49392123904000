import { CanActivateFn } from "@angular/router";
import { environment } from "../../../environments/environment";
import { AuthService } from "../services/auth.service";
import { inject } from "@angular/core";
import { Store } from '@ngrx/store';
import { first, mergeMap } from 'rxjs';
import { selectUserToken } from '../../store/root/user/user.selectors';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const store = inject(Store)
  let requireSSO = true;

  if (environment.allowCognitoAuth) {
    requireSSO = false;
  }
  return store.select(selectUserToken).pipe(
    first(),
    mergeMap(async token => {
      if (token !== "") {
        return true
      } else {
        return await authService.checkUserLoginStatus(true, requireSSO);
      }
    })
  )
};
