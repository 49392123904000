import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Store } from "@ngrx/store"
import { Observable, filter, first, mergeMap } from "rxjs"
import { selectUserToken } from "../../store/root/user/user.selectors"

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(request: HttpRequest<{}>, next: HttpHandler): Observable<HttpEvent<{}>> {
        if (!request.url.toUpperCase().includes("/TOKEN/")) {
            return this.store.select(selectUserToken).pipe(
                filter(token => token !== ""),
                mergeMap(token => {
                    request = request.clone({
                        setHeaders: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                    return next.handle(request)
                })
            )
        }
        return next.handle(request)
    }
}