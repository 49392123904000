import { User } from "../../../shared/types/user";

const emptyUser: User = {
  companyId: "",
  companyName: "",
  email: "",
  firstName: "",
  id: "",
  lastName: "",
  permissions: [],
  roles: [],
  username: ""
}

export interface UserState{
  userInfo: User
  userToken: string
}

export const userInitialState: UserState = {
    userInfo: emptyUser,
    userToken: ""
};

