import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { Auth } from "@aws-amplify/auth";
import "zone.js";

if (environment.production) {
    enableProdMode();
}

Auth.configure(environment.cognito);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
