<div class="app">
  <div class="header">
    <srd-header
      (sideNavClicked)="sideNavOpened = !sideNavOpened"
      [includeSideNav]="links.length > 0"
      [profileName]="profileName"
      [profileCompany]="profileCompany"
    >
    </srd-header>
  </div>

  <div class="container">
    <srd-side-nav
      [visible]="sideNavOpened"
      [collapse]="true"
      [links]="links"
      (linkClicked)="updateSideNav($event)"
      >
    </srd-side-nav>

    <div class="content">
      <div class="router-outlet gray-bg">
        <router-outlet>
        </router-outlet>
      </div>
      <div class="footer">
        <srd-footer></srd-footer>
      </div>
    </div>
  </div>
</div>
