export interface CognitoCredentials {
  username: string,
  email: string,
  cognitoToken: string
}

export const cognitoCredsInitialState: CognitoCredentials = {
    username: "",
    email: "",
    cognitoToken: ""
};


