"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectToPascal = exports.toPascal = exports.toCamel = exports.toSnake = exports.objectToSnake = exports.objectToCamel = void 0;
var caseConvert_1 = require("./caseConvert");
Object.defineProperty(exports, "objectToCamel", { enumerable: true, get: function () { return caseConvert_1.objectToCamel; } });
Object.defineProperty(exports, "objectToSnake", { enumerable: true, get: function () { return caseConvert_1.objectToSnake; } });
Object.defineProperty(exports, "toSnake", { enumerable: true, get: function () { return caseConvert_1.toSnake; } });
Object.defineProperty(exports, "toCamel", { enumerable: true, get: function () { return caseConvert_1.toCamel; } });
Object.defineProperty(exports, "toPascal", { enumerable: true, get: function () { return caseConvert_1.toPascal; } });
Object.defineProperty(exports, "objectToPascal", { enumerable: true, get: function () { return caseConvert_1.objectToPascal; } });
