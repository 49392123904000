import { Component } from "@angular/core";

@Component({
    selector: "unauthorized",
    templateUrl: "./unauthorized.component.html",
    styleUrl: "./unauthorized.component.scss"
})
export class UnauthorizedComponent {

}
