import {createAction, props} from "@ngrx/store";
import { User } from "../../../shared/types/user";

export const userActionTypes = {
    userTokenFetch: "userTokenFetch"
};

export const userTokenFetchAction = createAction(
    userActionTypes.userTokenFetch,
    props<{user: User, token: string}>()
);


