import { cognitoCredsInitialState } from "./cognito-creds.state";
import { createReducer, on } from "@ngrx/store";
import { successfulCognitoLoginAction } from "./cognito-creds.actions";

export const cognitoCredsReducer = createReducer(
    cognitoCredsInitialState,
    on(successfulCognitoLoginAction, (state, {username, email, cognitoToken}) => (
        {...state, username: username, email: email, cognitoToken: cognitoToken }
    )
    ),
);
