import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  childSideNavLinks,
  NavLinkTitle,
  sideNavLinks,
} from './constants/routes.constant';
import { AuthService } from './shared/services/auth.service';
import { Store } from '@ngrx/store';
import {
  selectCognitoCredentials,
  selectUser,
} from './store/root/root.selectors';
import { CognitoCredentials } from './store/root/cognito-creds/cognito-creds.state';
import { jwtDecode } from 'jwt-decode';
import { userTokenFetchAction } from './store/root/user/user.actions';
import { DecodedUserToken } from './shared/types/decoded-user-token';
import { Subscription } from 'rxjs';
import { objectToCamel } from 'ts-case-convert';
import { User } from './shared/types/user';
import { SideNavLink } from '@srd/app-common-components/lib/models/side-nav-link';
import { RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'admin_client';
  sideNavOpened: boolean = true;
  links = sideNavLinks;
  childLinks = childSideNavLinks;
  linkTitle = NavLinkTitle;

  profileName = '';
  profileCompany = '';

  credentialSub?: Subscription = undefined;
  userSub?: Subscription = undefined;

  constructor(private store: Store, private authService: AuthService) {}

  async ngOnInit(): Promise<void> {
    this.credentialSub = this.store
      .select(selectCognitoCredentials)
      .subscribe(async (value: CognitoCredentials) => {
        if (value.email) {
          this.authService
            .fetchMapToken(value.cognitoToken, value.email)
            .subscribe((tokenResponse) => {
              const userToken = tokenResponse.token;
              const decodedToken = jwtDecode<DecodedUserToken>(userToken);
              const user: User = { ...objectToCamel(decodedToken.data) };
              this.store.dispatch(
                userTokenFetchAction({ user: user, token: userToken })
              );
            });
        }
      });
    this.userSub = this.store.select(selectUser).subscribe((user) => {
      this.profileName = user.userInfo.firstName + ' ' + user.userInfo.lastName;
      this.profileCompany = user.userInfo.companyName!;
    });
  }

  ngOnDestroy() {
    this.credentialSub!.unsubscribe();
    this.userSub!.unsubscribe();
  }

  updateSideNav(title: string) {

    let linksArray: SideNavLink[][] = [...sideNavLinks];

    if (title === NavLinkTitle.IdentityManagement || title === 'Users' || title === 'Capabilities' || title === 'Permissions') {
      linksArray['1']['3'].children = undefined;
      linksArray['1']['0'].children = this.childLinks['iam'];
      return this.links = linksArray;
    } else if (title === NavLinkTitle.AppConfiguration || title === 'Hubble' || title === 'Architect' || title === 'RADR') {
      linksArray['1']['0'].children = undefined;
      linksArray['1']['3'].children = this.childLinks['ac'];
      return this.links = linksArray;
    } else {
      linksArray['1']['0'].children = undefined;
      linksArray['1']['3'].children = undefined;
      return this.links = [...sideNavLinks]
    }
  }
}
