import {CanActivateFn, Router} from "@angular/router";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { selectUser } from "../../store/root/root.selectors";
import { firstValueFrom } from "rxjs";
import { UserState } from "../../store/root/user/user.state";
import { environment } from "../../../environments/environment";

export const adminPermissionGuard: CanActivateFn = async (route, state) => {
    const store = inject(Store);
    const router = inject(Router);
    const userState = await firstValueFrom<UserState>(store.select(selectUser));

  if(userState.userInfo.permissions!.find(value => value.permissionName === environment.adminPermissionName)) {
    return true;
  }
  return router.createUrlTree(['unauthorized'])
};
