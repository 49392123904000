import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './shared/components/home/home.component';
import { authGuard } from './shared/guards/auth.guard';
import { adminPermissionGuard } from './shared/guards/admin-permission.guard';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'identity-mgmt',
    canActivate: [authGuard, adminPermissionGuard],
    loadChildren: () =>
      import('./modules/identity-management/identity-management.module').then((m) => {
        return m.IdentityManagementModule;
      }),
  },
  {
    path: 'user-mgmt',
    canActivate: [authGuard, adminPermissionGuard],
    loadChildren: () =>
      import('./modules/user-management/user-management.module').then((m) => {
        return m.UserManagementModule;
      }),
  },
  {
    path: 'capability-mgmt',
    canActivate: [authGuard, adminPermissionGuard],
    loadChildren: () =>
      import('./modules/capability-management/capability-management.module').then((m) => {
        return m.CapabilityManagementModule;
      }),
  },
  {
    path: 'permission-mgmt',
    canActivate: [authGuard, adminPermissionGuard],
    loadChildren: () =>
      import('./modules/permission-management/permission-management.module').then((m) => {
        return m.PermissionManagementModule;
      }),
  },
  {
    path: 'service-mgmt',
    canActivate: [authGuard, adminPermissionGuard],
    loadChildren: () =>
      import('./modules/service-management/service-management.module').then(
        (m) => {
          return m.ServiceManagementModule;
        }
      ),
  },
  {
    path: 'data-mgmt',
    canActivate: [authGuard, adminPermissionGuard],
    loadChildren: () =>
      import('./modules/data-management/data-management.module').then((m) => {
        return m.DataManagementModule;
      }),
  },
  {
    path: 'app-config',
    canActivate: [authGuard, adminPermissionGuard],
    loadChildren: () =>
      import('./modules/app-configuration/app-configuration.module').then(
        (m) => {
          return m.AppConfigurationModule;
        }
      ),
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
